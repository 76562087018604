<script>
  import Button from "../components/Button.svelte";
  import Section from "../components/Section.svelte";

  let opacity = 1;

  const onClick = () => {
    opacity = opacity === 1 ? 0 : 1;
    console.log("AAA");
  };
</script>

<Section secondary small>
  <div class="container">
    <div>
      <p>
        Sie bekommen Ihre eigene <br />TV-Reportage <span>•LIVE</span>
        auf Ihrem Event!
      </p>
      <a href="mailto:info@eggsklusiv.de">
        <Button>JETZT BUCHEN</Button>
      </a>
    </div>
    <div class="video">
      <img src="/img/polygon.svg" style="opacity:{opacity}" />
      <a
        href="https://www.youtube.com/watch?v=HH2LvG_Piz8"
        target="_blank"
        style="pointer-events:all;"
      >
        <div class="dummy" />
        <iframe
          width="100%"
          height="100%"
          title="video"
          style="pointer-events:none;"
          src="https://www.youtube-nocookie.com/embed/HH2LvG_Piz8"
          frameborder="0"
          allowfullscreen
        />
      </a>
    </div>
  </div>
</Section>

<style>
  p {
    font-family: "Avant Garde";
    font-size: 2rem;
    font-weight: bold;
  }

  span {
    color: #f61717;
  }

  .container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .video {
    position: relative;
    flex: 1;
    max-width: 512px;
    margin: -4rem -4rem -4rem 2rem;
  }

  .video img {
    position: absolute;
    top: -8rem;
    left: -10rem;
    pointer-events: none;
  }

  @media (max-width: 800px) {
    .container {
      flex-direction: column;
    }
    .video {
      width: calc(100% + 8rem);
      height: 20rem;
      max-width: none;
      margin: 4rem -4rem -4rem -4rem;
    }

    .video img {
      display: none;
    }
  }

  .dummy {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
</style>
