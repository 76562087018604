<script>
  export let src;
  export let title;
</script>

<div class="container">
  <img {src} />
  <h4>{title}</h4>
  <p><slot /></p>
</div>

<style>
  .container {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 1rem;
  }

  h4 {
    font-size: 1.5rem;
    margin: 0;
  }

  img {
    width: 80px;
    max-height: 80px;
    grid-row: 1/3;
    grid-column: 1;
  }

  p {
    grid-row: 2;
    grid-column: 2;
    margin: 0;
    opacity: 0.6;
  }
</style>
