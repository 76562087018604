<script>
  export let secondary = false;
  export let small = false;
  export let id;
</script>

<section class:secondary {id}>
  <img src="/img/ellipse.svg" class="el1" />
  <img src="/img/bgshape.svg" class="el2" />
  <div class="content" class:small><slot /></div>
</section>

<style>
  section {
    position: relative;
    background: var(--bg-1);
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  .secondary {
    background: var(--bg-2);
  }

  .content {
    z-index: 1;
    padding: 8rem 4rem;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: var(--max-width);
  }

  .small {
    padding: 4rem;
  }

  @media (max-width: 800px) {
    .content {
      z-index: 1;
      padding: 6rem 2rem;
      width: 100%;
      max-width: var(--max-width);
    }

    .small {
      padding: 4rem 2rem;
    }
  }

  .el1 {
    position: absolute;
    width: 90vw;
    min-width: 100rem;
    left: 0;
    top: 0;
    opacity: 0.5;
    z-index: 0;
  }
  .el2 {
    position: absolute;
    width: 100vw;
    min-width: 100rem;
    left: 0;
    opacity: 0.5;
    top: 50%;
    z-index: 0;
  }
</style>
