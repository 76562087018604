<script>
  import { onMount } from "svelte";
  import Button from "../components/Button.svelte";
  import { particleStars } from "../particleStars";

  let y;

  onMount(() => {
    particlesJS("hero", particleStars);
  });
</script>

<svelte:window bind:scrollY={y} />

<article id="hero">
  <div class="city" style="transform: translate(0,{y / 4}px)" />
  <div class="stars" />
  <img class="ellipse" src="/img/ellipse.svg" />
  <img class="bgshape" src="/img/bgshape.svg" />
  <div class="wrapper">
    <div class="gunni" style="transform: translate(0,{y / 6}px);">
      <img src="/img/günni2.png" />
    </div>
  </div>
  <div class="wrapper">
    <section>
      <img class="gitter" src="/img/Gitter.svg" />
      <div>
        Das schonungslose<br /> <b>Wahrheits-Magazin</b>
      </div>
      <p>
        Wir präsentieren die Wahrheit und lügen dabei, was das Zeug hält. Wie
        entstehen Fake-News? eggsklusiv zeigt es am eigenen Leib. Ausgefallen,
        aber nicht ausfallend.
      </p>
      <Button>JETZT BUCHEN</Button>
    </section>
  </div>
</article>

<style>
  article {
    background: linear-gradient(
      180deg,
      rgba(10, 12, 28, 1) 0%,
      rgba(30, 36, 87, 1) 100%
    );
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  section {
    position: relative;
    font-family: "Avant Garde";
    padding: 4rem;
    width: 100%;
    max-width: var(--max-width);
    font-size: 4rem;
    display: flex;
    align-items: flex-start;
    justify-items: flex-start;
    flex-direction: column;
  }

  p {
    max-width: 35rem;
    font-size: 1.5rem;
    margin-bottom: 6rem;
  }

  .wrapper {
    position: absolute;
    /* overflow: hidden; */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    top: 147px;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 560px;
  }

  @media (max-width: 800px) {
    .wrapper {
      top: 9rem;
      align-items: flex-start;
    }
    p {
      margin-bottom: 2rem;
    }

    section {
      padding: 2rem;
      font-size: 3rem;
    }
    .gitter {
      transform: translate(-4rem, -0rem);
    }
    .gunni {
      width: 90% !important;
    }
  }

  .city {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-repeat: repeat-x;
    background-position: center -2rem;
    background-image: url("/img/city.png");
  }

  .stars {
    z-index: 0;
    mix-blend-mode: lighten;
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: repeat-x;
    background-position: center top;
    background-image: url("/img/stars.png");
  }

  .ellipse {
    position: absolute;
  }

  .bgshape {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-40%);
  }

  .gunni {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: var(--max-width);
  }

  .gunni img {
    position: absolute;
    bottom: -10rem;
    right: -10vw;
    width: 120%;
    max-width: 53rem;
    z-index: 2;
  }

  .gitter {
    position: absolute;
    top: 1rem;
    left: 0;
  }
</style>
