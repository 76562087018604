<script>
  import { scrollto } from "svelte-scrollto";
</script>

<nav>
  <img src="img/logo.svg" width="140px" alt="eggsklusiv" />
  <ul>
    <li><a use:scrollto={"#versionen"}>Versionen</a></li>
    <li>
      <a use:scrollto={"#anlaesse"}>Anlässe</a>
    </li>
    <li><a use:scrollto={"#referenzen"}>Referenzen</a></li>
    <li><a use:scrollto={"#rezensionen"}>Rezensionen</a></li>
    <li><a use:scrollto={"#kontakt"}>Kontakt</a></li>
  </ul>
</nav>

<style>
  nav {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    padding: 3rem 4rem;
  }

  ul {
    list-style: none;
    display: flex;
    text-transform: uppercase;
    flex: 1;
    max-width: 40rem;
    justify-content: space-between;
    font-family: "Avant Garde";
  }

  li a {
    user-select: none;
    cursor: pointer;
    color: white;
  }

  li a:hover {
    text-decoration: none;
    color: var(--primary-light);
  }

  @media (max-width: 800px) {
    ul {
      display: none;
    }
    nav {
      padding: 2rem;
    }
  }
</style>
