<script>
  export let src;
  export let author;
</script>

<div class="container">
  <div class="sb">
    <slot />

    <p>~{author}</p>
  </div>
  <img {src} />
</div>

<style>
  .container {
    display: flex;
    max-width: 50rem;
    justify-content: space-between;
    align-items: start;
    gap: 4rem;
    margin: 2rem 0;
  }
  .container:nth-of-type(even) {
    flex-direction: row-reverse;
  }

  .sb {
    position: relative;
    word-break: break-word;
    background: var(--bg-2);
    padding: 1rem;
    flex: 1;
    box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.2),
      inset 0px 2px 0px rgba(0, 0, 0, 0.2);
    font-size: 1.5rem;
    border-radius: 0.5rem 0 0.5rem 0.5rem;
  }

  .container:nth-of-type(even) .sb {
    border-radius: 0 0.5rem 0.5rem 0.5rem;
  }

  p {
    opacity: 0.6;
    font-size: 1rem;
    text-align: end;
    margin: 1rem 0 0 0;
  }

  img {
    height: 10rem;
    margin-top: -1rem;
  }

  .sb:before {
    content: "";
    width: 21px;
    height: 20px;
    clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
    background: var(--bg-2);
    box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    right: -20px;
    top: 0px;
  }

  .container:nth-of-type(even) .sb:before {
    content: "";
    width: 21px;
    height: 20px;
    position: absolute;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
    left: -20px;
    top: 0px;
  }
  @media (max-width: 800px) {
    img {
      margin-top: 0rem;
      height: 8rem;
    }
  }
</style>
