<script>
  import { particleStars } from "../particleStars";
  import { onMount } from "svelte";

  onMount(() => {
    particlesJS("referenzen", particleStars);
  });
</script>

<article>
  <div class="city" id="referenzen" />
  <div class="stars" />

  <div class="wrapper">
    <div class="header">
      <div class="block" />
      <h1>REFERENZEN</h1>
      <div class="block" />
    </div>
    <section>
      <p>
        3 x LVM Versicherung • 3 x Data Tech GmbH • <b>2 x Siemens AG</b> • 2 x
        Deutsche Post World Net • 2 x EVB BauPunkt GmbH • 2 x Marco Friedrich
        GmbH • 2 x Herdt-Verlag GmbH • Alper & Schetter Consulting GmbH •
        Arbeiter Samariter Bund • Bechtle GmbH & Co. KG • Boehringer Ingelheim •
        Bongrain Deutschland GmbH • Cellent AG • <b>Commerzbank AG</b> • Dachser
        SE • Datenzentrale Baden-Württemberg • Delta Lloyd Deutschland • DETEC
        Dec. Techn. Software • Dr. Vollmer GmbH • EK/servicegroup eG •
        <b>ERGO</b>
        • FrankenData • Germania Steuerberatungsgesellschaft mbH • Glöckle Firmengruppe
        • Grace Europe Holding GmbH • IG Metall • IKEA Ind. Mat.+Serv. GmbH • JUTEC
        Biegesystem GmbH • Kassel International Management School • Kellner Telecom
        GmbH • Kemper System GmbH & Co. KG •
        <b>Liebherr GmbH</b> • Martin Braun KG • Massenberg GmbH • Matec
        Maschinenbau GmbH • Medical Tribune Verlagsgesellschaft mbH • MHT Mold &
        Hotrunner Technology AG • <b>Mitsubishi Electric Europe</b> • NIS GmbH
        Novell GmbH • OKE Group GmbH • Opterra Wössingen GmbH • Paramount Home
        Entertainment • Pfalzgraf-Konditorei • Pirelli Deutschland • Putzmeister
        AG • Riedwerke • SCC Bad Schwalbacher Carneval Club e.V. •
        <b>Schöller</b>
        • Schultz KG • SEZ AG •Solartron Deutschland • Sparkasse Iserlohn • Sports
        Up GmbH • SWG Schraubenwerk Gaisbach GmbH •TCT TechnikCentrumThale GmbH •
        TDS AG •
        <b>TetraPak</b> • Tokheim Service GmbH & Co. KG • Tüfa-Team GmbH • Volkswagen
        Originalteile GmbH & Co. KG • Weber-Stephen Deutschland
      </p>
    </section>
  </div>
</article>

<style>
  article {
    background: linear-gradient(
      180deg,
      rgba(10, 12, 28, 1) 0%,
      rgba(30, 36, 87, 1) 100%
    );
    width: 100%;
    /* height: 100%; */
    overflow: hidden;
    position: relative;
  }

  h1 {
    margin: 0;
    font-size: 10vw;
    line-height: 7vw;
    opacity: 0.06;
  }

  section {
    position: relative;
    font-family: "Avant Garde";

    margin: 2rem;
    max-width: var(--max-width);
    text-transform: uppercase;
    text-align: justify;
    color: var(--primary);
    font-size: 1.5rem;
  }

  .wrapper {
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
  }

  @media (max-width: 800px) {
    h1 {
      margin: 0;
      margin-bottom: 2rem;
      font-size: 17vw;
      line-height: 11vw;
      opacity: 0.06;
    }
    .block {
      height: 11vw !important;
    }
  }

  .city {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    opacity: 0.4;
    background-repeat: repeat-x;
    background-position: center -2rem;
    background-image: url("/img/city.png");
  }

  .stars {
    z-index: 0;
    mix-blend-mode: lighten;
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: repeat-x;
    background-position: center top;
    background-image: url("/img/stars.png");
  }

  .block {
    background: white;
    width: 100%;
    height: 100%;
    opacity: 0.06;
  }

  .header {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
</style>
